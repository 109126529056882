<!-- pagina de  Inicio-->
<template>
    <v-container fill-height fluid class="black fondo">
    <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="12" class="d-flex justify-center" >
            <!-- Animacion de la llave para Desktop  -->
                <v-img
                    width="100%"
                    max-width="300"
                    class="escudo d-none d-md-flex justify-center align-center mb-5"
                    :src="require('../assets/llave_2.png')"  
                    
                >
                    <v-img
                        max-width="200"
                        class="llave ml-n4"
                        :src="require('../assets/llave_1.png')"  
                    >
                    </v-img>
                </v-img>
                <!-- Animacion de la llave para Mobile-->
                <v-img
                    width="100%"
                    max-width="200"
                    class="escudo d-md-none justify-center align-center mb-15 mt-n10"
                    :src="require('../assets/llave_2.png')"  
                >
                    <v-img
                        max-width="100"
                        class="llave mt-3"
                        :src="require('../assets/llave_1.png')"  
                    >
                    </v-img>
                </v-img>
        </v-col>      
        </v-row>
    </v-container>
</template>
<script>
    import axios from "axios";

    export default {
        name: 'Home',
        components: {
        },
        methods: {
            cambiarP(lugar){//función para cambiar de pagina 
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    error => console.log('Hubo un error: ' + error)
                )
            },
            startTimer () {//función para cambiar la pagina despues de 2500ms
                this.timer = setTimeout(() => {
                    this.cambiarP('Edad')
                    // console.log("execute me")
                }, 2500)
            }
        },
        created(){ 
            this.startTimer()
        }
    }
</script>
<style scoped>
    .fondo{
        background-image: url("../assets/homeEscritorio.jpg");
        background-position-x: center;
        background-size: cover;
    }
    @media (max-width: 960px){
        .fondo{
            background-image: url("../assets/homeMobile.jpg");
            background-position-x: center;
            background-size: cover;
        }
    }
    .escudo{ 
        z-index: 2;
        animation: pulse 2s infinite;
    }

    .llave {
        animation: pulse2 2s infinite;
        position: absolute;
        left: 25%;
        top: 12%;
        
    }

    @keyframes pulse {
        0% {
        transform: scale(1);
    }

        50% {
        transform: scale(1.1);
    }
    
        100% {
        transform: scale(1);
    }
    }

    @keyframes pulse2 {
        0% {
        transform: scale(1);
    }

        50% {
        transform: scale(1.3);
    }
    
        100% {
        transform: scale(1);
    }
    }
</style>